<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="100" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">							
							<!-- <el-button type="primary" size="mini" @click="queren(scope.row)">确定为优秀承办单位</el-button>							
							<el-button type="warning" size="mini" @click="bohui(scope.row)">驳回</el-button> -->
						</template>
					</MyTable>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				tableHeader: [
					{
						name: '单位名称',
						prop: 'organizational_name',
						width: ''
					},
					{
						name: '提案标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					
				],
				tableData: [],
				
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {	
			queren(row){
				this.$confirm('是否确定为优秀承办单位？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/proposa/sdyxdw',
						params: {
							id: row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
				
				})
			},
			bohui(row){
				this.$confirm('是否驳回优秀承办单位？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/proposa/qxyxdw',
						params: {
							id: row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
				
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/cbdwindex',
					params: {
						yxdwtype:'4',
						organizational_name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					res.list.forEach((item, index) => {
						let cbdw_type = 1 //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
						if (item.dfhtype == 1 && item.state == 2) {
							cbdw_type = 4
						} else if (item.dfhtype != 1) {
							cbdw_type = item.dfhtype
						} else if (item.dfhtype == 1 && item.sfcn == 0) {
							cbdw_type = 1
						} else if (item.dfhtype == 1 && item.sfcn == 1) {
							cbdw_type = 5
						} else if (item.dfhtype == 1 && item.sfcn == 2) {
							cbdw_type = 6
						}
						item.cbdw_type = cbdw_type
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>